import { Injectable } from '@angular/core';
import { createStore, setProp, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  getActiveEntity,
  hasEntity,
  resetActiveId,
  selectActiveEntity,
  selectActiveId,
  setActiveId,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { filter, map, Observable } from 'rxjs';

import {
  ISFilters,
  ISFiltersInitialStateHelper,
  ISFiltersKey,
  ISFiltersPath,
  ISFiltersUpdate,
} from '@ess/integrated-search/filters/shared/utils';
import { IntegratedSearchProjectsDataAccessFacade } from '@ess/integrated-search/projects/data-access';
import { ISStoreEnum, ISUser } from '@ess/integrated-search/shared/utils';
import { SharedTimespanEnum } from '@ess/shared/utils/enums';
import { EssRequestParamsHelper, SharedTimespanHelper } from '@ess/shared/utils/helpers';
import { SharedActiveEntitiesStore, SharedStoreValue, SharedTimespan } from '@ess/shared/utils/models';

interface StoreProps {
  user: string | null;
}

type Store = SharedActiveEntitiesStore<ISFilters, number, StoreProps>;

@Injectable()
export class IntegratedSearchFiltersDataAccessRepository {
  readonly activeProject$: Observable<number | undefined>;

  private readonly __store: Store;

  get activeEntity(): ISFilters | undefined {
    return this.__store.query(getActiveEntity());
  }

  constructor(private readonly __projectsFacade: IntegratedSearchProjectsDataAccessFacade) {
    this.__store = this.__createStore();
    this.__persistState();

    this.activeProject$ = this.__store.pipe(selectActiveId());
  }

  getInitialState(projectId?: number): ISFilters {
    const active = this.activeEntity;
    const defaultCountry: string | undefined = this.__projectsFacade.activeProjectEntity?.default_country;
    return ISFiltersInitialStateHelper.getInitialState(projectId ?? active?.projectId ?? -1, defaultCountry);
  }

  getFilters$<K extends ISFiltersPath>(path: K, key: keyof ISFilters[K]): Observable<ISFilters[K][typeof key]> {
    return this.__store.pipe(
      selectActiveEntity(),
      filter(Boolean),
      map((filters: ISFilters) => filters[path][key]),
    );
  }

  setActiveUser(user: ISUser): void {
    const currentUser: string | null = this.__store.getValue().user;
    if (!currentUser || currentUser === user.email) {
      this.__store.update(setProp('user', user.email));
    } else {
      this.__store.update(setProp('user', user.email), deleteAllEntities());
    }
  }

  setActiveFilters(projectId: number | null): void {
    if (projectId) {
      if (this.__store.query(hasEntity(projectId))) {
        this.__store.update(setActiveId(projectId));
      } else {
        this.__store.update(addEntities(this.getInitialState(projectId)), setActiveId(projectId));
      }
    } else {
      this.__store.update(resetActiveId());
    }
  }

  updateActiveFilters<K extends ISFiltersPath>(path: K, key: keyof ISFilters[K], value?: ISFiltersUpdate): void {
    const filters: ISFilters | undefined = this.activeEntity;
    if (!filters) return;

    let updatedFilters: ISFilters = { ...filters };
    if (value) {
      updatedFilters = {
        ...filters,
        [path]: {
          ...filters[path],
          [key]: EssRequestParamsHelper.getData({ ...filters[path][key], ...value }),
        },
      };
    }

    this.__store.update(updateEntities(filters.projectId, updatedFilters));
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.FILTERS },
      withProps<StoreProps>({
        user: null,
      }),
      withEntities<ISFilters, 'projectId'>({ idKey: 'projectId' }),
      withActiveId(),
    );
  }

  private __persistState(): void {
    persistState(this.__store, {
      key: 'integratedSearchFiltersDataAccess',
      storage: localStorageStrategy,
      source: () => this.__store.pipe(excludeKeys(['ids', 'activeId'])),
      preStoreInit: (value: SharedStoreValue<Store>) => this.__preStoreInitChecks(value),
    });
  }

  private __preStoreInitChecks(value: SharedStoreValue<Store>): SharedStoreValue<Store> {
    const entities: Record<number, ISFilters> = value.entities;
    Object.entries(entities).forEach(([key, value]) => {
      entities[+key] = this.__checkTimespan(value);
      entities[+key] = this.__checkInitialState(value);
    });
    return value;
  }

  private __checkTimespan(filters: ISFilters): ISFilters {
    const minAvailableDate = SharedTimespanHelper.getMinAvailableDate();
    Object.keys(filters).forEach((path) => {
      Object.keys(filters[path as ISFiltersPath]).forEach((key) => {
        const timespan = filters[path as ISFiltersPath][key as ISFiltersKey]['timespan'] as SharedTimespan | undefined;

        if (timespan?.start_date && timespan?.end_date) {
          if (timespan.preset) {
            Object.assign(timespan, SharedTimespanHelper.getPresetTimespan(timespan.preset));
          } else if (new Date(timespan.start_date).getTime() < minAvailableDate.getTime()) {
            Object.assign(timespan, SharedTimespanHelper.getPresetTimespan(SharedTimespanEnum.DAYS_7));
          }
        }
      });
    });

    return filters;
  }

  private __checkInitialState(filters: ISFilters): ISFilters {
    const initialState: ISFilters = this.getInitialState();
    Object.keys(initialState).forEach((path) => {
      Object.keys(initialState[path as ISFiltersPath]).forEach((key) => {
        if (filters[path as ISFiltersPath][key as ISFiltersKey] === undefined) {
          filters[path as ISFiltersPath][key as ISFiltersKey] =
            initialState[path as ISFiltersPath][key as ISFiltersKey];
        }
      });
    });
    return filters;
  }
}
