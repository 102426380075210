import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, finalize, map, Observable, of, Subject, tap } from 'rxjs';

import {
  ISCampaignsFilterValue,
  ISDynamicFiltersHelper,
  ISFiltersHelper,
  ISTacticFilters,
  ISTacticsDynamicFiltersTriggers,
  ISUrlsFilterValue,
} from '@ess/integrated-search/filters/shared/utils';
import { IS_TACTICS_KEYS, ISTacticsEnum } from '@ess/integrated-search/tactics/shared/utils';
import { essDeepCompareHelper } from '@ess/shared/utils/helpers';
import { SharedFilterOption } from '@ess/shared/utils/models';

import { AbstractIntegratedSearchFiltersDataAccessFacade } from './integrated-search-filters-data-access.facade.abstract';

import { IntegratedSearchFiltersDataAccessApiService } from '../services/integrated-search-filters-data-access-api.service';

@UntilDestroy()
@Injectable()
export class IntegratedSearchFiltersDataAccessTacticsFacade extends AbstractIntegratedSearchFiltersDataAccessFacade<'tactics'> {
  private readonly __campaignsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly __campaignsFilterOption$: Subject<SharedFilterOption> = new Subject<SharedFilterOption>();
  private readonly __urlFiltersLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly __urlsFilterOption$: Subject<SharedFilterOption> = new Subject<SharedFilterOption>();

  private __previousFiltersState: ISTacticsDynamicFiltersTriggers | null = null;

  get campaignsLoading$(): Observable<boolean> {
    return this.__campaignsLoading$.asObservable();
  }

  get campaignsFilterOption$(): Observable<SharedFilterOption> {
    return this.__campaignsFilterOption$.asObservable();
  }

  get urlsLoading$(): Observable<boolean> {
    return this.__urlFiltersLoading$.asObservable();
  }

  get urlsFilterOption$(): Observable<SharedFilterOption> {
    return this.__urlsFilterOption$.asObservable();
  }

  constructor(protected readonly __filtersApi: IntegratedSearchFiltersDataAccessApiService) {
    super();
    this._listenFiltersChanges();
  }

  protected _getDynamicFiltersRequest(filters: ISTacticFilters): Observable<ISTacticFilters> {
    this._dynamicFiltersChanged =
      this._key !== 'searchGaps' &&
      (!this.__previousFiltersState ||
        this.__previousFiltersState.country !== filters.country ||
        !essDeepCompareHelper(filters.timespan, this.__previousFiltersState.timespan));

    if (this._dynamicFiltersChanged) {
      this.__previousFiltersState = { ...filters };
      switch (this._key) {
        case IS_TACTICS_KEYS[ISTacticsEnum.CHANNEL_OVERLAP]:
          return this.__getCampaigns(this.activeProjectId, filters).pipe(
            map((campaigns) => ({
              ...filters,
              ...campaigns,
            })),
          );
        case IS_TACTICS_KEYS[ISTacticsEnum.LANDING_PAGE_OPTIMISATION]:
          return this.__getUrls(this.activeProjectId, filters).pipe(map((urls) => ({ ...filters, ...urls })));
      }
    }

    return of(filters);
  }

  private __getCampaigns(
    projectId: number,
    { timespan: { start_date, end_date }, country, ...filters }: ISTacticFilters,
  ): Observable<ISCampaignsFilterValue> {
    this.__campaignsLoading$.next(true);
    return this.__filtersApi.getCampaigns(projectId, { countries: [country], start_date, end_date }).pipe(
      tap((campaigns) => this.__campaignsFilterOption$.next(ISFiltersHelper.getCampaignFilterOptions(campaigns))),
      map((campaigns) => ISDynamicFiltersHelper.getUpdatedCampaignsFilters(filters, campaigns)),
      finalize(() => this.__campaignsLoading$.next(false)),
    );
  }

  private __getUrls(
    projectId: number,
    { timespan: { start_date, end_date }, country, ...filters }: ISTacticFilters,
  ): Observable<ISUrlsFilterValue> {
    this.__urlFiltersLoading$.next(true);
    return this.__filtersApi.getUrls(projectId, { country, start_date, end_date }).pipe(
      tap((urls) => this.__urlsFilterOption$.next(ISFiltersHelper.getUrlFilterOptions(urls))),
      map((urls) => ISDynamicFiltersHelper.getUpdatedUrlsFilters(filters, urls)),
      finalize(() => this.__urlFiltersLoading$.next(false)),
    );
  }
}
