import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, finalize, map, Observable, of, Subject, tap } from 'rxjs';

import {
  ISCampaignsFilterValue,
  ISDynamicFiltersHelper,
  ISFiltersHelper,
  ISUniverseDynamicFiltersTriggers,
  ISUniverseFilters,
} from '@ess/integrated-search/filters/shared/utils';
import { essDeepCompareHelper } from '@ess/shared/utils/helpers';
import { SharedFilterOption } from '@ess/shared/utils/models';

import { AbstractIntegratedSearchFiltersDataAccessFacade } from './integrated-search-filters-data-access.facade.abstract';

import { IntegratedSearchFiltersDataAccessApiService } from '../services/integrated-search-filters-data-access-api.service';

@UntilDestroy()
@Injectable()
export class IntegratedSearchFiltersDataAccessUniverseFacade extends AbstractIntegratedSearchFiltersDataAccessFacade<'universe'> {
  private readonly __campaignsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly __campaignsFilterOption$: Subject<SharedFilterOption> = new Subject<SharedFilterOption>();

  private __previousFiltersState: ISUniverseDynamicFiltersTriggers | null = null;

  get campaignsLoading$(): Observable<boolean> {
    return this.__campaignsLoading$.asObservable();
  }

  get campaignsFilterOption$(): Observable<SharedFilterOption> {
    return this.__campaignsFilterOption$.asObservable();
  }

  constructor(protected readonly __filtersApi: IntegratedSearchFiltersDataAccessApiService) {
    super();
    this._listenFiltersChanges();
  }

  protected _getDynamicFiltersRequest(filters: ISUniverseFilters): Observable<ISUniverseFilters> {
    this._dynamicFiltersChanged =
      this._key === 'searchTerms' &&
      (!this.__previousFiltersState ||
        !essDeepCompareHelper(filters.countries, this.__previousFiltersState.countries) ||
        !essDeepCompareHelper(filters.timespan, this.__previousFiltersState.timespan));

    if (this._dynamicFiltersChanged) {
      this.__previousFiltersState = { ...filters };

      return this.__getCampaigns(this.activeProjectId, filters).pipe(
        map((campaigns) => ({
          ...filters,
          ...campaigns,
        })),
      );
    }

    return of(filters);
  }

  private __getCampaigns(projectId: number, filters: ISUniverseFilters): Observable<ISCampaignsFilterValue> {
    this.__campaignsLoading$.next(true);
    return this.__filtersApi
      .getCampaigns(projectId, {
        countries: filters.countries,
        start_date: filters.timespan?.start_date,
        end_date: filters.timespan?.end_date,
      })
      .pipe(
        tap((campaigns) => this.__campaignsFilterOption$.next(ISFiltersHelper.getCampaignFilterOptions(campaigns))),
        map((campaigns) => ISDynamicFiltersHelper.getUpdatedCampaignsFilters(filters, campaigns)),
        finalize(() => this.__campaignsLoading$.next(false)),
      );
  }
}
