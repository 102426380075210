import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import {
  ISCampaignsAdGroupsFilter,
  ISCampaignsAdGroupsFilterParams,
  ISCampaignsAdGroupsFilterResponse,
  ISUrlFilterParams,
  ISUrlsFilterResponse,
} from '@ess/integrated-search/filters/shared/utils';
import { SharedDataAccessApiService } from '@ess/shared/data-access';
import { SharedFeatureServicesUserInteractionService } from '@ess/shared/feature/services';
import { EssRequestParamsHelper } from '@ess/shared/utils/helpers';

@Injectable()
export class IntegratedSearchFiltersDataAccessApiService {
  private readonly __resource = 'projects';

  constructor(
    private readonly __api: SharedDataAccessApiService,
    private readonly __uiService: SharedFeatureServicesUserInteractionService,
  ) {}

  getCampaigns(projectId: number, params: ISCampaignsAdGroupsFilterParams): Observable<ISCampaignsAdGroupsFilter[]> {
    return this.__api
      .get<ISCampaignsAdGroupsFilterResponse>(`${this.__resource}/${projectId}/campaigns`, {
        params: EssRequestParamsHelper.getParams(params),
      })
      .pipe(
        map((response) => response.items),
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching campaigns and ad groups. Please try again later.',
            },
          });
          return of([]);
        }),
      );
  }

  getUrls(projectId: number, params: ISUrlFilterParams): Observable<string[]> {
    return this.__api
      .get<ISUrlsFilterResponse>(`${this.__resource}/${projectId}/google-ads-urls`, {
        params: EssRequestParamsHelper.getParams(params),
      })
      .pipe(
        map((response) => response.items),
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching urls. Please try again later.',
            },
          });
          return of([]);
        }),
      );
  }
}
