import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { map } from 'rxjs';

import { IntegratedSearchAuthDataAccessAuthActions as AuthActions } from '@ess/integrated-search/auth/data-access';
import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from '@ess/integrated-search/projects/data-access';

import { IntegratedSearchFiltersDataAccessRepository } from './integrated-search-filters-data-access.repository';

@Injectable()
export class IntegratedSearchFiltersDataAccessEffects {
  setActiveFilters$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.setActiveProjectSuccess),
      map(({ projectId }) => this.__repo.setActiveFilters(projectId)),
    ),
  );

  setActiveUser$ = createEffect((actions) =>
    actions.pipe(
      ofType(AuthActions.authSuccessFinished),
      map(({ user }) => this.__repo.setActiveUser(user)),
    ),
  );

  constructor(private readonly __repo: IntegratedSearchFiltersDataAccessRepository) {}
}
