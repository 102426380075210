import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, filter, map, Observable, ReplaySubject, switchMap } from 'rxjs';

import {
  IS_FILTERS_KEY,
  IS_FILTERS_PATH,
  ISAppliedFiltersHelper,
  ISFilters,
  ISFiltersHelper,
  ISFiltersInitialStateHelper,
  ISFiltersOrderHelper,
  ISFiltersPath,
  ISPredefinedFilters,
} from '@ess/integrated-search/filters/shared/utils';
import { essDeepCompareHelper, essDeepMergeHelper, EssRequestParamsHelper } from '@ess/shared/utils/helpers';
import { SharedFilters } from '@ess/shared/utils/models';

import { IntegratedSearchFiltersDataAccessRepository } from './integrated-search-filters-data-access.repository';

@Injectable()
export abstract class AbstractIntegratedSearchFiltersDataAccessFacade<K extends ISFiltersPath> {
  readonly filters$: Observable<ISFilters[K][typeof this._key]>;
  readonly stateChanged$: Observable<boolean>;

  protected readonly _filters$: ReplaySubject<ISFilters[K][typeof this._key]> = new ReplaySubject<
    ISFilters[K][keyof ISFilters[K]]
  >(1);

  protected get activeProjectId(): number {
    return this._repo.activeEntity!.projectId;
  }

  protected readonly _path: K = inject<K>(IS_FILTERS_PATH);
  protected readonly _key: keyof ISFilters[K] = inject<keyof ISFilters[K]>(IS_FILTERS_KEY);
  protected readonly _repo: IntegratedSearchFiltersDataAccessRepository = inject(
    IntegratedSearchFiltersDataAccessRepository,
  );
  protected _dynamicFiltersChanged = false;

  protected constructor() {
    this.filters$ = this._filters$.asObservable();
    this.stateChanged$ = this.filters$.pipe(map(this.__hasStateChanged.bind(this)));
  }

  getAppliedFilters$(options$: Observable<SharedFilters>): Observable<SharedFilters> {
    return combineLatest([options$, this.filters$]).pipe(
      map(([options, filters]) =>
        ISAppliedFiltersHelper.mapAppliedFiltersValues(options, filters as ISPredefinedFilters).sort(
          ISFiltersOrderHelper.appliedFiltersSort(this._path, this._key),
        ),
      ),
    );
  }

  refreshFilters(): void {
    this._repo.updateActiveFilters(this._path, this._key);
  }

  updateFilters(value: Partial<ISFilters[K][typeof this._key]>): void {
    this._repo.updateActiveFilters(this._path, this._key, value);
  }

  updateAppliedFilters(filters: SharedFilters): void {
    // TODO find another way
    const clearFilters = (ISFiltersInitialStateHelper.getOptionalEmptyValues()[this._path] as any)[this._key];
    const updatedState = essDeepMergeHelper(clearFilters, ISFiltersHelper.mapFilterValues(filters));
    this.updateFilters({ ...updatedState });
  }

  getState(): ISFilters[K][typeof this._key] {
    return this._repo.activeEntity![this._path][this._key];
  }

  resetFilters(): void {
    this.updateFilters(this._repo.getInitialState()[this._path][this._key]);
  }

  protected _listenFiltersChanges(): void {
    this._repo
      .getFilters$(this._path, this._key)
      .pipe(
        switchMap(this._getDynamicFiltersRequest.bind(this)),
        filter((filters) => {
          if (this._dynamicFiltersChanged) {
            this.updateFilters(filters);
            return false;
          }
          return true;
        }),
        untilDestroyed(this),
      )
      .subscribe((filters) => this._filters$.next(filters));
  }

  protected abstract _getDynamicFiltersRequest(
    filters: ISFilters[K][typeof this._key],
  ): Observable<ISFilters[K][typeof this._key]>;

  private __hasStateChanged(state: ISFilters[K][typeof this._key]): boolean {
    const initialState: Params = EssRequestParamsHelper.getData(this._repo.getInitialState()[this._path][this._key]);
    const currentState: Params = EssRequestParamsHelper.getData(state);
    return !essDeepCompareHelper(currentState, initialState);
  }
}
