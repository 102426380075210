import { NgModule } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';

import { IntegratedSearchFiltersDataAccessApiService } from './services/integrated-search-filters-data-access-api.service';
import { IntegratedSearchFiltersDataAccessEffects } from './state/integrated-search-filters-data-access.effects';
import { IntegratedSearchFiltersDataAccessRepository } from './state/integrated-search-filters-data-access.repository';

@NgModule({
  providers: [
    IntegratedSearchFiltersDataAccessRepository,
    provideEffects(IntegratedSearchFiltersDataAccessEffects),
    IntegratedSearchFiltersDataAccessApiService,
  ],
})
export class IntegratedSearchFiltersDataAccessModule {}
